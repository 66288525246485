import RadioSelectionBars from '../../components/RadioSelectionBars/radioSelectionBars';
import { GC_LANGUAGE, QUESTIONNAIRE_QUESTIONS } from '../../constants/constants';
import './pit_styling.scss';
import * as Yup from 'yup';
import { useFormik, FormikProvider, FieldArray } from 'formik';
import Button from '../../components/Button/button';
import { Offcanvas } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Context } from '../../store/store';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';

function FamilyQuestionnaire({ onBtnClick, onBackClick }) {
  const [state] = useContext(Context);
  const [showMessage, setShowMessage] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ msg: false });

  const formik = useFormik({
    initialValues: { family_history: state.testRequestInfo.family_history },
    validationSchema: Yup.object({
      family_history: Yup.object({
        QNR_FHX_1: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_2: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_3: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_4: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_5: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_6: Yup.object({
          response: Yup.string().required('Required'),
        }),
        QNR_FHX_7: Yup.object({
          response: Yup.string().required('Required'),
        }),
      }),
    }),
    onSubmit: values => {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Family Questionnaire');
      const msg = searchParams.get('msg');
      if (
        Object.keys(values.family_history)
          .map(key => values.family_history[key].response)
          .includes('YES') &&
        msg === 'false'
      ) {
        setShowMessage(true);
      } else {
        onBtnClick(values);
      }
    },
  });

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/order?step=questionnaire', 'PIT Flow: Family Questionnaire');
  }, []);

  const handleCanvasClose = () => {
    const params = new URLSearchParams();
    params.append('step', 'questionnaire');
    params.append('msg', 'true');
    setSearchParams(params);
    setShowMessage(false);
  };

  return (
    <div className='questionnaire_wrapper'>
      <div className='pit_flow_title'>Tell us about your personal and family history</div>
      <div style={{ fontSize: '12px', alignSelf: 'flex-start', color: '#7C7E83' }}>
        We are asking these questions to help determine whether MyOme&apos;s screening tests are appropriate for you.
      </div>
      <FormikProvider value={formik}>
        <FieldArray name='family_history'>
          {() => (
            <div>
              {Object.keys(formik.values.family_history).map(questionId => (
                <div key={questionId}>
                  <div className='question_cell'>
                    <div className='question_wording' style={{ marginRight: questionId === 'QNR_FHX_5' ? '35px' : '' }}>
                      {formik.values.family_history[questionId].question_text}
                      {formik.touched.family_history && formik.errors.family_history && (
                        <span className='required_error_styling' style={{ marginLeft: '8px' }}>
                          {formik.errors.family_history[questionId]?.response}
                        </span>
                      )}
                    </div>
                    <div data-sentry-block>
                      <RadioSelectionBars
                        options={QUESTIONNAIRE_QUESTIONS[questionId].options}
                        direction={'row'}
                        type={'radio'}
                        name={questionId}
                        id={questionId}
                        showBorder={true}
                        formik={formik}
                        index={questionId}
                        onChange={value => {
                          formik.setFieldValue(`family_history[${questionId}]response`, value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </FieldArray>
      </FormikProvider>
      <Offcanvas show={showMessage} placement='top' onHide={() => setShowMessage(false)} className='gc_canvas_wrapper'>
        <Offcanvas.Body>
          <div style={{ fontSize: '22px', textAlign: 'center', fontWeight: '600', color: '#003595' }}>
            Special considerations given your personal and family history
          </div>
          <div className='gc_canvas_body'>
            {GC_LANGUAGE.map((gc, i) => {
              return (
                <div key={i} style={{ marginBottom: '18px' }}>
                  {gc}
                </div>
              );
            })}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button
              buttonLabel='Cancel Order'
              styleClass='dangerButtonStyling'
              buttonStyling={{ marginRight: '12px' }}
              onClick={() => {
                setShowMessage(false);
                window.location.href = 'http://www.myome.com';
              }}
            />
            <Button
              buttonLabel='Proceed Anyway'
              styleClass='primaryButtonStyling'
              dataTestId='proceedAnyway'
              onClick={() => handleCanvasClose()}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div style={{ marginTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          buttonLabel='Previous'
          styleClass='secondaryButtonStyling'
          buttonStyling={{ marginRight: '18px' }}
          onClick={() => onBackClick('checkout')}
        />
        <Button
          buttonLabel='Next'
          dataTestId='questionnaireNextBtn'
          styleClass='primaryButtonStyling'
          onClick={formik.handleSubmit}
        />
      </div>
    </div>
  );
}

export default FamilyQuestionnaire;
